import type {Store} from 'react-redux';
import {authentication, authorization} from '@onsmart/auth-client';
import {all, call, cancel, fork, spawn, take, takeLatest} from 'redux-saga/effects';

import {RouteEnum} from 'components/App';
import {history} from 'config/history';
import {sagaRouter} from 'utils/sagaRouter';
import {safe} from 'utils/sagas';

import {environment} from '../environment';
import {appSaga} from './global/sagas';
import {activitiesPageSaga} from './modules/activities';
import {assetFormSaga} from './modules/assetForm';
import inventoryPageSagaPrism from './modules/inventory/prism';
import {inventoryPageSaga} from './modules/inventory/sagas';
import marketsPageSagaPrism from './modules/markets/prism';
import {marketsPageSaga} from './modules/markets/sagas';
import {panelFormSaga} from './modules/panelForm';
import panelsPageSagaPrism from './modules/panels/prism';
import {panelsPageSaga} from './modules/panels/sagas';
import quintileDataSetsPrism from './modules/quintileDataSets/prism';
import showingsPrism from './modules/showings/prism';

import type {SagaMiddleware} from 'redux-saga';
import type {Saga} from 'utils/sagas';
const routes = {
  [RouteEnum.inventory]: inventoryPageSagaPrism,
  [RouteEnum.panels]: panelsPageSagaPrism,
  [RouteEnum.markets]: marketsPageSagaPrism,
  [RouteEnum.showings]: showingsPrism,
  [RouteEnum.quintileDataSets]: quintileDataSetsPrism,
};

function* fetchInitialData() {
  const ACCOUNT_URL = environment.settings.auth.account;
  const AUTH_BASE_URL = environment.settings.auth.gateway;
  const CORE_API_URL = environment.settings.apiUrl.coreApiGraphCdn;

  // Authentication Flow
  yield call(authentication.sagas.authenticationFlow, ACCOUNT_URL, AUTH_BASE_URL);

  // Authorization Flow
  const accessToken = yield call(authentication.getToken);
  yield call(authorization.sagas.userFetch, accessToken, CORE_API_URL);
}

export const rootSaga = safe(function* () {
  yield* fetchInitialData();

  yield takeLatest(authorization.actions.unauthorize, authentication.actions.logout);

  //TODO: set app state to ready. This must say that app is ready to continue
  //yield put(ready());

  yield fork(sagaRouter, history, routes);

  const sagas = [
    appSaga,
    inventoryPageSaga,
    marketsPageSaga,
    panelsPageSaga,
    assetFormSaga,
    panelFormSaga,
    activitiesPageSaga,
  ];

  yield all(sagas.map(spawn));
});

const __DEV__ = !!(module as NodeModule & {hot: boolean}).hot;
const CANCEL_SAGAS_HMR = 'CANCEL_SAGAS_HMR';

function createAbortableSaga(saga: Saga) {
  if (__DEV__) {
    return function* main() {
      const sagaTask = yield fork(saga);
      yield take(CANCEL_SAGAS_HMR);
      yield cancel(sagaTask);
    };
  }
  return saga;
}

const saga = {
  startSaga<T>(sagaMiddleware: SagaMiddleware<T>) {
    return sagaMiddleware.run(createAbortableSaga(rootSaga));
  },
  cancelSaga(store: Store<any>) {
    store.dispatch({type: CANCEL_SAGAS_HMR});
  },
};

export default saga;
