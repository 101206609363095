import type {FetchQueryOptions, QueryKey, InvalidateQueryFilters} from 'react-query';
import {QueryClient} from 'react-query';
import {authentication} from '@onsmart/auth-client';
import {GraphQLClient} from 'graphql-request';

import {environment} from 'config/environment';

import type {GraphQLError, RequestDocument, Variables} from 'graphql-request/dist/types';

export {gql} from 'graphql-request';

interface ApolloGraphQLError extends Omit<GraphQLError, 'path'> {
  extensions: {
    code: string;
  };
}

export interface GraphqlCoreError {
  message: string;
  response: {
    errors: ApolloGraphQLError[];
  };
}

interface RequestQueryOptions {
  requestHeaders?: HeadersInit;
  cache?: {
    key: string;
    staleTime: number;
  };
}

interface FetchQueryParams<TResult, TVariables> extends FetchQueryOptions<TResult> {
  queryKey: QueryKey;
  queryDocument?: RequestDocument;
  queryVariables?: TVariables;
}

const gqlClientInstance = new GraphQLClient(environment.settings.apiUrl.coreApiGraphCdn, {
  headers: {
    'Content-Type': 'application/json',
    'X-Proxy-Headers': 'true',
    authorization: `Bearer ${authentication.getToken()}`,
  },
});

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // global 20s staleTime to deduplicate identical requests in this time frame
      staleTime: 1000 * 20,
    },
  },
});

export const gqlRequest = <T = any, V = Variables>(
  query: RequestDocument,
  variables?: V,
): Promise<T> => {
  gqlClientInstance.setHeader('authorization', `Bearer ${authentication.getToken()}`);

  return gqlClientInstance.request<T, V>(query, variables);
};

export const fetchQuery = <TResult, TVariables = Variables>({
  queryKey,
  queryDocument,
  queryVariables,
  ...options
}: FetchQueryParams<TResult, TVariables>): Promise<TResult> => {
  const queryFn = () => gqlRequest<TResult, TVariables>(queryDocument, queryVariables);

  return queryClient.fetchQuery({queryKey, queryFn, ...options});
};

export const prefetchQuery = <TResult, TVariables = Variables>({
  queryKey,
  queryDocument,
  queryVariables,
  ...options
}: FetchQueryParams<TResult, TVariables>): Promise<void> => {
  const queryFn = () => gqlRequest<TResult, TVariables>(queryDocument, queryVariables);

  return queryClient.prefetchQuery({queryKey, queryFn, ...options});
};

export const invalidateQuery = (filters: InvalidateQueryFilters) => {
  queryClient.invalidateQueries(filters);
};

export class CoreClient {
  private static instance: CoreClient;

  private constructor() {}

  static getInstance() {
    if (!this.instance) {
      this.instance = new CoreClient();
    }

    return this.instance;
  }

  request<T = any, V = Variables>(
    query: RequestDocument,
    variables?: V,
    options?: RequestQueryOptions,
  ): Promise<T> {
    gqlClientInstance.setHeader('authorization', `Bearer ${authentication.getToken()}`);

    const {requestHeaders, cache} = Object.assign({}, options);

    if (cache) {
      const {key, ...cacheOptions} = cache;

      return queryClient.fetchQuery(
        [key, variables],
        () => gqlClientInstance.request<T, V>(query, variables, requestHeaders),
        cacheOptions,
      );
    } else {
      return gqlClientInstance.request<T, V>(query, variables, requestHeaders);
    }
  }
}
